import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { ScteComponent } from "./scte.component";
import { LogDetailDialogComponent } from "./log-detail-dialog/log-detail-dialog.component";

// Services
import { LogDetailModalService } from "./log-detail-modal.service";

// Routes
import { ScteRouting } from "./scte.routing";

@NgModule({
    imports: [CommonModule, FormsModule, NgSelectModule, NgbModule, FontAwesome, SharedModule, ScteRouting],
    declarations: [ScteComponent, LogDetailDialogComponent],
    providers: [LogDetailModalService]
})
export class ScteModule {}
