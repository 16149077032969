import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../guards/auth.guard";
import { Constants } from "../../constants/constants";

// Components
import { ScteComponent } from "./scte.component";

const scteRoutes: Routes = [
    {
        path: Constants.urls.logs.scte,
        component: ScteComponent,
        canActivate: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.SCTE35",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/YAARSQ"
                },
                {
                    menu: "HELP_MENU.ACTIVATING_SCTE35_LOGGING",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/GQE7SQ"
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(scteRoutes)],
    exports: [RouterModule]
})
export class ScteRouting {}
